// Default Einstellungen
@import 'uikit/scss/uikit-mixins';
@import 'uikit/scss/uikit-variables';


// Partials

// Core
@import 'uikit/scss/core/grid';
@import 'uikit/scss/core/utility';
@import 'uikit/scss/core/flex';
@import 'uikit/scss/core/modal';

// Components
@import 'uikit/scss/components/tooltip';
@import 'uikit/scss/components/sticky';


// Overwrites

.uk-container {
  margin: 0 auto;
}
