// Spaces sind definiert in den Settings

@each $wup-key, $wup-value in $wup-spaces {

  // Paddings, Innenabstand

  .wup-padding-#{$wup-key} {
    padding: $wup-value !important;
  }

  .wup-padding-top-#{$wup-key} {
    padding-top: $wup-value !important;
  }

  .wup-padding-right-#{$wup-key} {
    padding-right: $wup-value !important;
  }

  .wup-padding-bottom-#{$wup-key} {
    padding-bottom: $wup-value !important;
  }

  .wup-padding-left-#{$wup-key} {
    padding-left: $wup-value !important;
  }

  .wup-padding-x-#{$wup-key} {
    padding-left: $wup-value !important;
    padding-right: $wup-value !important;
  }

  .wup-padding-y-#{$wup-key} {
    padding-bottom: $wup-value !important;
    padding-top: $wup-value !important;
  }


  // Margins, Außenabstand

  .wup-margin-#{$wup-key} {
    margin: $wup-value !important;
  }

  .wup-margin-top-#{$wup-key} {
    margin-top: $wup-value !important;
  }

  .wup-margin-right-#{$wup-key} {
    margin-right: $wup-value !important;
  }

  .wup-margin-bottom-#{$wup-key} {
    margin-bottom: $wup-value !important;
  }

  .wup-margin-left-#{$wup-key} {
    margin-left: $wup-value !important;
  }

  .wup-margin-x-#{$wup-key} {
    margin-left: $wup-value !important;
    margin-right: $wup-value !important;
  }

  .wup-margin-y-#{$wup-key} {
    margin-bottom: $wup-value !important;
    margin-top: $wup-value !important;
  }

}


// Fixes

.wup-no-padding,
.wup-padding-none { padding: 0 !important; }

.wup-no-padding-left,
.wup-padding-left-none { padding-left: 0 !important; }

.wup-no-padding-right,
.wup-padding-right-none { padding-right: 0 !important; }

.wup-no-padding-top,
.wup-padding-top-none { padding-top: 0 !important; }

.wup-no-padding-bottom,
.wup-padding-bottom-none { padding-bottom: 0 !important; }

.wup-no-padding-y,
.wup-padding-y-none { padding-bottom: 0 !important; padding-top: 0 !important; }

.wup-no-padding-x,
.wup-padding-x-none { padding-left: 0 !important; padding-right: 0 !important; }



.wup-no-margin,
.wup-margin-none { margin: 0 !important; }

.wup-no-margin-left,
.wup-margin-left-none { margin-left: 0 !important; }

.wup-no-margin-right,
.wup-margin-right-none { margin-right: 0 !important; }

.wup-no-margin-top,
.wup-margin-top-none { margin-top: 0 !important; }

.wup-no-margin-bottom,
.wup-margin-bottom-none { margin-bottom: 0 !important; }

.wup-no-margin-y,
.wup-margin-y-none { margin-bottom: 0 !important; margin-top: 0 !important; }

.wup-no-margin-x,
.wup-margin-x-none { margin-left: 0 !important; margin-right: 0 !important; }
