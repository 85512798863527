a,
.wup-icon-left {

  i {
    margin-right: 6px;
  }
}

.wup-icon-right {

  i{
    margin-left: 6px;
    margin-right: 0;
  }
}
