#nav {
  padding: 3px 0;
  display: inline-block;
  width: 100%;
  background: $wup-white;

  a {
    background: map-get($wup-color-project, blue);
    display: inline-block;
    text-align: center;
    padding: 15px 4px;
    color: $wup-white;

    &:hover {
      background: map-get($wup-color-project, bluedark);
    }
    &:active {
      background: map-get($wup-color-project, bluelight);
    }


    // Virtuelles Haus

    &.prj--virtual-house {
      background: map-get($wup-color-project, red);
      float: left;
      width: 49.75%;

      @media(min-width: $breakpoint-medium) {
        width: 24%;
      }

      @media(min-width: $breakpoint-large) {
        width: 20%;
      }

      &:hover {
        background: map-get($wup-color-project, reddark);
      }
      &:active {
        background: map-get($wup-color-project, redlight);
      }
    }


    // Mobile Nav Toggle

    &.prj--mobile-nav {
      margin-left: .5%;
      width: 49.75%;
    }
  }


  // Aktiver Menüpunkt

  a.prj--is-active,
  .prj--is-active a,
  .current-menu-item a,
  .current-menu-parent a {
    background: map-get($wup-color-project, bluedark);
    position: relative;

    &:before {
      font-family: "FontAwesome", "Font Awesome 5 Free";
      content: fa-content($fa-var-angle-down);
      font-weight: 900;
      line-height: 1;
      text-align: center;
      position: absolute;
      bottom: -18px;
      left: 50%;
      width: 100%;
      height: 18px;
      width: 90px;
      margin-left: -45px;
      background: map-get($wup-color-project, red);
      z-index: 999;
      border-radius: 0 0 5px 5px;
    }
  }


  // Navigation List

  #nav-main {
    display: none;
    float: left;
    width: 76%;

    @media(min-width: $breakpoint-medium) {
      display: block;
    }

    @media(min-width: $breakpoint-large) {
      width: 80%;
    }
  }

  ul {
    list-style-type: none;
    padding: 0 0 0 3px;
    display: flex;
    margin: 0;

    li {
      border-right: 1px solid map-get($wup-color-project, bluedark);
      position: relative;
      flex: 1;

      &:last-child {
        border-right: 0;
      }


      &:hover {

        a {

          &:before {
            background: map-get($wup-color-project, bluedark);
          }
        }

        ul {
          display: block;
        }
      }


      a {
        width: 100%;
      }


      &.prj--virtual-house-mobile {
        display: none;
      }


      // Home Links

      &.prj--home-link {
        width: 60px;
        position: relative;
        flex: none;

        a {
          font-size: 0px;

          &:before {
            border-radius: 0;
            left: 0;
            margin-left: 0;
            width: 100%;
            height: 4px;
            bottom: -4px;
          }

          &:after {
            @media(min-width: $breakpoint-medium) {
              font-family: "FontAwesome", "Font Awesome 5 Free";
              font-size: $wup-font-size-tablet;
              content: fa-content($fa-var-home);
              margin-left: -16px;
              font-weight: 900;
            }

            @media(min-width: $breakpoint-large) {
              font-size: $wup-font-size-desktop;
            }
          }
        }

        ul {
          display: none !important;
        }
      }

      ul {
        background: map-get($wup-color-project, blue);
        padding: 0;
        position: absolute;
        padding-top: 22px;
        display: none;
        z-index: 555;
        // overflow-y: scroll;
        // -webkit-overflow-scrolling: touch;
        // max-height: 620px;
        // max-height: 88vh;

        li {
          border-right: 0;
          width: 100%;

          &:first-child {

            a {
              border-top: 0;
            }
          }

          a {
            text-align: left;
            text-transform: none;
            letter-spacing: 0;
            font-size: $wup-font-size-small;
            padding: 8px 10px 8px 28px;
            border-top: 1px solid map-get($wup-color-project, bluedark);
            background: map-get($wup-color-project, blue) !important;
            position: relative;

            &:before {
              font-family: "FontAwesome", "Font Awesome 5 Free";
              content: fa-content($fa-var-angle-right) !important;
              font-weight: 900;
              margin-right: 6px !important;
              color: $wup-white !important;
              background: none !important;
              left: 14px !important;
              width: auto !important;
              bottom: inherit !important;
              height: 22px !important;
              top: 50%;
              margin-top: -11px;
              margin-left: 0 !important;
            }

            &:hover,
            &.prj--is-active {
              background: map-get($wup-color-project, bluedark) !important;

              &:before {
                color: map-get($wup-color-project, red) !important;
              }
            }
          }
        }
      }
    }
  }
}



// Off Canvas

.mm-menu {

  * {
    font-family: inherit;
  }

  .prj--virtual-house-mobile {

    a {
      color: map-get($wup-color-project, red);
    }
  }
}
