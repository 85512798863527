a {
  color: $wup-link-color-default;
  text-decoration: none;

  &:hover {
    color: $wup-link-color-hover;
  }

  &:active {
    color: $wup-link-color-active;
  }
}
