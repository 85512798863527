*,
*:before,
*:after {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

main {
  display: block;
}

.uk-container {
	box-sizing: content-box;
}
