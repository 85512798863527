.wup-print-hidden {

  @media print {
    display: none !important;
  }
}

.wup-print-visible {
  display: none !important;

  @media print {
    display: block !important;
  }
}

.wup-print-visible-inline {
  display: none !important;

  @media print {
    display: inline !important;
  }
}

.wup-print-visible-inline-block {
  display: none !important;

  @media print {
    display: inline-block !important;
  }
}


// NORMALIZED

.wup-print-normalize {
  display: inherit !important;

  @media print {
    display: inherit !important;
  }
}
