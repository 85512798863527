@keyframes rotateThis45 {
  from { transform: rotate(0deg); }
  to { transform: rotate(45deg); }
}

@-webkit-keyframes spotPulse {
  0%   { -webkit-transform: scale(1); opacity: 1; }
  100% { -webkit-transform: scale(3); opacity: 0; }
}
@-moz-keyframes spotPulse {
  0%   { -moz-transform: scale(1); opacity: 1; }
  100% { -moz-transform: scale(3); opacity: 0; }
}
@-o-keyframes spotPulse {
  0%   { -o-transform: scale(1); opacity: 1; }
  100% { -o-transform: scale(3); opacity: 0; }
}
@keyframes spotPulse {
  0%   { transform: scale(1); opacity: 1; }
  100% { transform: scale(3); opacity: 0; }
}
