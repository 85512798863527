.uk-modal-header {
  position: absolute;
  text-align: right;
  margin-bottom: 0;
  top: -58px;
  right: 0;
  left: 0;

  .uk-modal-headline {
    margin: 0;
  }

  a {
    background: $wup-white;
    vertical-align: middle;
    display: inline-block;
    padding: 15px 20px;
    cursor: pointer;
    height: 54px;

    &.uk-modal-close {
      padding: 12px 20px;

      &:before {
        background-image: url(../img/modal-close.png);
        background-size: contain;
        display: block;
        height: 30px;
        content: '';
        width: 30px;
      }
    }
  }
}


.uk-modal-footer {
  margin-top: 15px;
  margin: 15px -20px -20px -20px;
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  border-radius: 0 0 4px 4px;
  background: #fafafa;

  @media(min-width: $breakpoint-medium) {
    margin: 3em -3em -3em -3em;
  }
}


.uk-modal-dialog {
  width: $breakpoint-xlarge;
  margin-top: 62px;

  @media(min-width: $breakpoint-medium) {
    padding: 3em;
  }
}
