@mixin disable-selection {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#wrapper {
  background: $wup-white;
  max-width: 1780px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 8px;
}

.uk-container {
  padding: 0;
}


.prj--icon-spin:before {
  content: fa-content($fa-var-cog) !important;
}
