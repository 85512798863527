// Typo Definition

body {
  color: $wup-body-color;
  font-family: $wup-font-family-default;
  font-size: $wup-font-size-smartphone;
  font-weight: $wup-font-weight-default;
  line-height: $wup-line-height-default;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: $breakpoint-small) {
    font-size: $wup-font-size-tablet;
  }

  @media (min-width: $breakpoint-large) {
    font-size: $wup-font-size-desktop;
  }
}


// Headlines

h1,
.wup-h1 {
  font-family: $wup-font-family-h1;
  font-size: $wup-font-size-h1;
  font-weight: $wup-font-weight-h1;
  line-height: $wup-line-height-h1;
}

h2,
.wup-h2 {
  font-family: $wup-font-family-h2;
  font-size: $wup-font-size-h2;
  font-weight: $wup-font-weight-h2;
  line-height: $wup-line-height-h2;
}

h3,
.wup-h3 {
  font-family: $wup-font-family-h3;
  font-size: $wup-font-size-h3;
  font-weight: $wup-font-weight-h3;
  line-height: $wup-line-height-h3;
}

h4,
.wup-h4 {
  font-family: $wup-font-family-h4;
  font-size: $wup-font-size-h4;
  font-weight: $wup-font-weight-h4;
  line-height: $wup-line-height-h4;
}

h5,
.wup-h5 {
  font-family: $wup-font-family-h5;
  font-size: $wup-font-size-h5;
  font-weight: $wup-font-weight-h5;
  line-height: $wup-line-height-h5;
}

h6,
.wup-h6 {
  font-family: $wup-font-family-h6;
  font-size: $wup-font-size-h6;
  font-weight: $wup-font-weight-h6;
  line-height: $wup-line-height-h6;
}


h1,
h2,
h3,
h4,
h5,
h6,
.wup-h1,
.wup-h2,
.wup-h3,
.wup-h4,
.wup-h5,
.wup-h6 {
  margin: 10px 0;
}

h1+h1,
h1+h2,
h1+h3,
h1+h4,
h1+h5,
h1+h6,
h2+h1,
h2+h2,
h2+h3,
h2+h4,
h2+h5,
h2+h6,
h3+h1,
h3+h2,
h3+h3,
h3+h4,
h3+h5,
h3+h6,
h4+h1,
h4+h2,
h4+h3,
h4+h4,
h4+h5,
h4+h6,
h5+h1,
h5+h2,
h5+h3,
h5+h4,
h5+h5,
h5+h6,
h6+h1,
h6+h2,
h6+h3,
h6+h4,
h6+h5,
h6+h6 {
  //margin-top:-.75em;
}



blockquote,
.wup-blockquote {
  font-size: 1.2em;
  font-style: italic;
  padding-left: 30px;
  position: relative;

  &:before {
    content: '\f10d';
    font-family: 'FontAwesome';
    left: 0;
    position: absolute;
    top: 0;
  }
}



// Schriftarten

@each $wup-key, $wup-value in $wup-fonts {

  .wup-font-family-#{$wup-key} {
    font-family: $wup-value !important;
  }

}



// Schriftschnitte

.wup-font-light {
  font-weight: 300;
}

.wup-font-normal {
  font-weight: 400;
}

.wup-font-bold {
  font-weight: 700;
}


.wup-font-weight-normalized {
  font-weight: $wup-font-weight-default !important;
}


// Text Aligns

.wup-text-center { text-align: center !important; }
.wup-text-left { text-align: left !important; }
.wup-text-right { text-align: right !important; }

.wup-text-normalized { text-align: $wup-text-align-default; }


// Schriftgrößen

.wup-font-big,
big {
  font-size: $wup-font-size-large;
}

.wup-font-small,
small {
  font-size: $wup-font-size-small;
}

.wup-font-size-normalized {
  font-size: $wup-font-size-smartphone !important;

  @media all and (min-width:$breakpoint-medium) {
    font-size: $wup-font-size-tablet !important;
  }

  @media all and (min-width:$breakpoint-large) {
    font-size: $wup-font-size-desktop !important;
  }
}


// Transform
.wup-text-uppercase {
  text-transform: uppercase;
}

.wup-text-lowercase {
  text-transform: lowercase;
}

.wup-text-transform-normalized {
  text-transform: none !important;
}


// Letter Spacings

.wup-letter-spacing-none {
  letter-spacing: normal;
}

.wup-letter-spacing {
  letter-spacing: 2px;
}

.wup-letter-spacing-big {
  letter-spacing: 4px;
}

.wup-letter-spacing-normalized {
  letter-spacing: $wup-letter-spacing-default !important;
}


// Text Decoration

.wup-text-line-through {
  text-decoration: line-through;
}

.wup-text-underline {
  text-decoration: underline;
}

.wup-text-decoration-normalized {
  text-decoration: $wup-letter-spacing-default !important;
}



// Hidden Text

.wup-hidden-text {
  height: 1px;
  overflow: hidden;
  position: absolute;
  text-indent: -10000px;
  width: 1px;
}


// HR Spacer

hr,
.wup-hr {
  border: 0;
  border-top: $wup-hr-border-width solid $wup-hr-border-color;
  margin: $wup-hr-margin-default;

  &.wup-hr-small {
    margin: $wup-hr-margin-small;
  }

  &.wup-hr-large {
    margin: $wup-hr-margin-large;
  }
}
