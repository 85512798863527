.prj--storelocator {
	@media (min-width: $breakpoint-medium) {
		display: flex;
		flex-wrap: wrap;
	}

	&--filter--check {
		@include disable-selection();

		cursor: pointer;
		background-color: map-get($wup-color-project, lightbluelight);
		text-align: center;

		&-icon {
			margin: map-get($wup-spaces, verysmall);
		}

		&-label {
			margin-top: map-get($wup-spaces, verysmall);
			margin-bottom: map-get($wup-spaces, verysmall);
			align-self: center;
			text-align: left;
		}

		&-info {
			display: flex;
			align-items: center;
			padding: map-get($wup-spaces, verysmall);

			&:hover {
				color: white;
				background-color: $wupit-color-primary;
			}
		}

		&.active {
			color: white;
			background-color: $wupit-color-primary;

			.prj--storelocator--filter--check-info:hover {
				color: white;
				background-color: $wupit-color-secondary;
			}
		}
	}

	&--hint-no-results {
		display: flex;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		border: 1px solid #CCC;

		&--label {
			font-size: 36px;
			font-weight: bold;
		}

		small {
			opacity: .5;
		}
	}

	.wup-sl-stuff {
		@media (min-width: $breakpoint-medium) {
			flex: 1;
			margin-left: 35px;
		}

		&.prj--storelocator--no-results .prj--storelocator--results {
			display: none;
		}
	}
	.wup-sl-map {
		position: relative;
		margin-bottom: map-get($wup-spaces, medium);

		@media (min-width: $breakpoint-medium) {
			flex: 1;
			margin-bottom: 0;
		}

		&.prj--storelocator--no-results .prj--storelocator--map--wrapper {
			opacity: .05;
		}

		&:not(.prj--storelocator--no-results) {
			.prj--storelocator--hint-no-results {
				display: none;
			}
		}
	}


	&--map {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		&--wrapper {
			position: relative;
			overflow: hidden;
			height: 100%;
		}

		&--placeholder {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&--indicator-hits {
		position: absolute;
		bottom: 0px;
		right: 0px;
		opacity: 0;
		transition: opacity 500ms linear;

		&-visible {
			opacity: .2;
		}
	}

	&--indicator-loading {
		position: absolute;
		top: 50%;
		right: 20px;
		opacity: 0;
		margin-top: -15px;
		transition: opacity 500ms linear;

		&-visible {
			opacity: .5;
		}
	}

	&--navigation {
		display: inline-block;
		margin: 16px 0 0;
		padding: 0;

		$size: 30px;
		a {
			display: inline-block;
			padding: 0 10px;
			box-sizing: border-box;
			min-width: $size;
			line-height: $size;
			height: $size;
			// border-radius: $size;
			cursor: pointer;
			vertical-align: top;
			color: $wupit-color-primary;

			&.active {
				color: white;
				background-color: $wupit-color-primary;
			}
		}
	}

	&--store {
		display: flex;
		color: $wupit-color-primary;
		background-color: map-get($wup-color-project, lightbluelight);
		position: relative;
		text-transform: none;
		letter-spacing: 0;

		&:hover {
			background-color: rgba(white, .75);
		}

		&--label {
			display: flex;
			align-items: center;
			padding: 8px;
			background: $wupit-color-primary;
			color: white;
			flex: none;
		}

		&-inner {
			flex: 1;
			padding: 10px 20px;
		}

		&--wrapper {
			margin: 0;
			padding: 0;

			> li {
				list-style: none;
				margin: 16px 0 0;
				padding: 0;

				background-color: map-get($wup-color-project, lightbluelight);
			}
		}

		&--distance {
			position: absolute;
			top: 5px;
			right: 10px;

			opacity: .5;
			font-size: $wup-font-size-small;
		}

		&--website {
			opacity: .5;
			font-size: $wup-font-size-small;
		}

		&--name {
			margin: 0;
			letter-spacing: 0;
		}

		&--thumbnail {
			width: 100px;
			margin-right: 20px;

			display: flex;
			align-items: center;
		}

		&--categories {
			display: inline-flex;
			align-items: center;

			padding-right: 16px;
			border: 1px solid;
			border-radius: 5px;
			margin-bottom: 12px;
			// background-color: $wupit-color-primary;
			// color: white;

			&-item {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				// width: 32px;
				height: 32px;
				margin-left: 16px;

				img {
					display: block;
					max-height: 24px;
					max-width: 24px;
				}

				i {
					margin: 0;
				}
			}
		}

		&--infowindow {
			min-width: 300px;
		}
	}

	&-detail {
		&--thumbnail {
			width: 100%;
			max-width: 250px;
			display: block;
			height: auto;
			margin: map-get($wup-spaces, medium) auto;

			&:first-child {
				margin-top: 0;
			}
		}

		&--categories {
			margin-left: -10px;
			margin-top: -10px;
		}

		&--category {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 70px;

			background-color: map-get($wup-color-project, lightbluelight);

			&-wrapper {
				padding-left: 10px;
				padding-top: 10px;
			}
		}

		&--contact-wrapper {
			margin-left: 20px;
			word-break: break-all;
			display: block;
		}
		&--contact-label {
			display: inline-block;
			text-indent: -20px;
			width: 40px;
			margin-right: 6px;
			font-family: map-get($wup-fonts, officinaserifbold);
		}
	}
}
