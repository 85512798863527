.prj--pagination {
  font-size: $wup-font-size-large;

  a {
    font-weight: normal;
  }

  .page-numbers {
    margin: 0 5px;
  }
}
