// Name:            Sticky
// Description:     Make elements remain at the top of the viewport
//
// Component:       `data-uk-sticky`
//
// States:          `uk-active`
//
// ========================================================================


// Variables
// ========================================================================

$sticky-z-index:                                 980 !default;

$sticky-animation-duration:                      0.2s !default;
$sticky-reverse-animation-duration:              0.2s !default;


/* ========================================================================
   Component: Sticky
 ========================================================================== */

/*
 * 1. More robust if padding and border are used
 */

[data-uk-sticky].uk-active {
   z-index: $sticky-z-index;
   /* 1 */
   box-sizing: border-box;
}

/*
 * Faster animations
 */

[data-uk-sticky][class*='uk-animation-'] {
    -webkit-animation-duration: $sticky-animation-duration;
    animation-duration: $sticky-animation-duration;
}

[data-uk-sticky].uk-animation-reverse {
    -webkit-animation-duration: $sticky-reverse-animation-duration;
    animation-duration: $sticky-reverse-animation-duration;
}


// Hooks
// ========================================================================

@include hook-sticky-misc();

// @mixin hook-sticky-misc(){}
