// Vertical Center

.wup-display-table {
  display: table;
  height: 100%;
  width: 100%;

  .wup-display-middle {
    display: table-cell;
    vertical-align: middle;
  }
}

.wup-display-inline {
  display: inline;
}

.wup-display-inline-block {
  display: inline-block;
}

.wup-display-block {
  display: block;
}


// Floatings

.wup-float-left {
  float: left !important;
}

.wup-float-right {
  float: right !important;
}

.wup-float-none {
  float: none !important;
}


// Fix für mehrspaltige Texte von Uikit - uikit coloums

[class*='uk-column-'] {
  :first-child {
    margin-top: 0;
  }
}
