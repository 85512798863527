.prj--hero {
  position: relative;


  .prj--fluid-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
  }


  .prj--overlay {
    position: absolute;
    margin-top: -3.2em;
    color: $wup-white;
    font-size: .75em;
    height: 6.4em;
    right: 0;
    top: 50%;
    left: 0;

    @media(min-width: $breakpoint-medium) {
      font-size: 1em;
    }
  }
}
