.wup-border-full {
  border: 1px solid $wup-border-default-color;
}


// Border Positions

.wup-border-top {
  border-top: 1px solid $wup-border-default-color;
}

.wup-border-right {
  border-right: 1px solid $wup-border-default-color;
}

.wup-border-bottom {
  border-bottom: 1px solid $wup-border-default-color;
}

.wup-border-left {
  border-left: 1px solid $wup-border-default-color;
}


// Border Axis Positions

.wup-border-x-axis {
  border-bottom: 1px solid $wup-border-default-color;
  border-top: 1px solid $wup-border-default-color;
}

.wup-border-y-axis {
  border-left: 1px solid $wup-border-default-color;
  border-right: 1px solid $wup-border-default-color;
}


// Border width's

.wup-border-width-2 {
  border-width: 2px;
}

.wup-border-width-3 {
  border-width: 3px;
}

.wup-border-width-4 {
  border-width: 4px;
}

.wup-border-width-5 {
  border-width: 5px;
}


// Border Radius

.wup-border-radius-rounded {
  border-radius: $wup-border-radius-rounded;
}

.wup-border-radius-round {
  border-radius: $wup-border-radius-round;
}

.wup-border-radius-squared {
  border-radius: $wup-border-radius-squared;
}
