@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8jyyi9');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?8jyyi9#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?8jyyi9') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?8jyyi9') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?8jyyi9##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.famo-sh-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.famo-sh-logo-knx {
  &:before {
    content: $famo-sh-logo-knx; 
  }
}

