@each $wup-groupName, $wup-groupData in $wup-colors {
    @each $wup-colorName, $wup-colorValue in $wup-groupData {

        $wup-color-light: lighten($wup-colorValue, 10%);
        $wup-color-dark: darken($wup-colorValue, 10%);

        .wup-color-#{$wup-colorName} { color: $wup-colorValue !important; }
        .wup-color-#{$wup-colorName}-light { color: $wup-color-light !important; }
        .wup-color-#{$wup-colorName}-dark { color: $wup-color-dark !important; }

        .wup-border-color-#{$wup-colorName} { border-color: $wup-colorValue !important; }
        .wup-border-color-#{$wup-colorName}-light { border-color: $wup-color-light !important; }
        .wup-border-color-#{$wup-colorName}-dark { border-color: $wup-color-dark !important; }

        .wup-background-#{$wup-colorName} { background: $wup-colorValue !important; }
        .wup-background-#{$wup-colorName}-light { background: $wup-color-light !important; }
        .wup-background-#{$wup-colorName}-dark { background: $wup-color-dark !important; }

    }
}
