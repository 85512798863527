b, strong {
  font-weight: normal;
  font-family: map-get($wup-fonts, officinasansbold);

  i {
    font-style: italic;
  }
}

i {
  font-style: normal;
  font-family: map-get($wup-fonts, officinasansitalic);

  b, strong {
    font-family: map-get($wup-fonts, officinasansbold);
    font-style: italic;
  }
}


h1,
.wup-h1,
h3,
.wup-h3,
h5,
.wup-h5 {
  text-transform: none;

  b, strong {
    font-weight: normal;
    font-family: map-get($wup-fonts, officinaserifbold);
  }
}


h2,
.wup-h2,
h4,
.wup-h4,
h6,
.wup-h6 {
  letter-spacing: 2px;
  text-transform: uppercase;

  b, strong {
    font-weight: normal;
    font-family: map-get($wup-fonts, officinasansbold);
  }
}

a {
  transition: all .2s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: map-get($wup-fonts, officinasansbold);
}

blockquote {
  margin-left: 0;
  padding-left: 2.6em;
  margin: 1.8em 0;

  &:before {
    font-size: 1.8em;
    top: -.5em;
  }
}

.wup-font-family-default {
  font-family: $wup-font-family-default;
}

.wup-blockquote::before, blockquote::before {
  font-family: FontAwesome,"Font Awesome 5 Free";
  font-weight: 900;
}
