// FALLBACK ANZEIGEN

.prj--is-too-small {
    display: none;

    @media (orientation: portrait) and (max-device-height: 639px) {
        display: block;
    }

    @media (orientation: landscape) and (max-width: 639px) {
        display: block;
    }

    @media (min-device-width: 1280px) and (min-device-height: 800px) {
        display: none !important;
    }
}

.prj--rotate-landscape {
    display: none;

    @media (orientation: portrait) and (min-device-height: 640px) and (max-width: $breakpoint-small-max) {
        display: block;
    }

    @media (min-device-width: 1280px) and (min-device-height: 800px) {
        display: none !important;
    }
}

.prj--small-window {
    display: none;

    @media (min-device-width: 1280px) and (min-device-height: 800px) and (max-width: $breakpoint-small-max) {
        display: block;
    }
}


// VIRTUELLES HAUS

.prj--vh {


    @media (max-width: $breakpoint-small-max) and (orientation: portrait) {
        display: none;
    }

    @media (max-width: 639px) and (orientation: landscape) {
        display: none;
    }


  .prj--vh-house {
    display: block;
    position: relative;
    overflow: hidden;
  }


  .prj--vh-house-img {
    display: block;
  }


  .prj--vh-nav {
    background: map-get($wup-color-project, red);
    display: inline-block;
    border-radius: 999px;
    margin-bottom: 2em;

    li {
      border-right: 1px solid map-get($wup-color-project, reddark);
      margin-right: 0;
      float: left;

      a {
        display: inline-block;
        font-weight: bold;
        font-size: .9em;
        padding: .6em 1em;
        color: $wup-white;

        @media(min-width: $breakpoint-medium) {
            font-size: 1.1em;
            padding: .6em 2em;
        }

        &:hover,
        &.prj--is-active {
          background: map-get($wup-color-project, reddark);
        }

        &.prj--is-active {
          position: relative;

          &:before {
            border-top: 12px solid map-get($wup-color-project, reddark);
            border-right: 12px solid transparent;
            border-left: 12px solid transparent;
            position: absolute;
            margin-left: -6px;
            bottom: -12px;
            content: '';
            height: 0;
            width: 0;
            left: 50%;
          }
        }
      }

      &:first-child {

        a {
          border-radius: 999px 0 0 999px;
        }
      }

      &:last-child {
        border-right: 0;

        a {
          border-radius: 0 999px 999px 0;
        }
      }
    }
  }


  .prj--vh-map {
    position: relative;
    overflow: hidden;

    .prj--vh-map-nav {

        display: none;

        @media(min-width: $breakpoint-medium) {
            display: block;
        }

      .prj--vh-map-nav-btn {
        background: map-get($wup-color-project, red);
        position: absolute;
        text-align: center;
        color: $wup-white;
        padding-top: 14px;
        font-size: 30px;
        height: 60px;
        width: 60px;
        left: 16px;
        top: 16px;

        &:hover {
          background: map-get($wup-color-project, reddark);
        }
      }

      ul {
        display: none;
        background: map-get($wup-color-project, lightbluedark);
        list-style-type: none;
        position: absolute;
        padding: 10px 0;
        left: 16px;
        top: 90px;
        margin: 0;

        li {

          a {
            letter-spacing: normal;
            text-transform: none;
            font-weight: normal;
            color: $wup-white;
            padding: 6px 20px;
            display: block;

            &:hover {
              background: darken(map-get($wup-color-project, lightbluedark), 5%);
            }
          }
        }

        &:before {
          border-bottom: 12px solid map-get($wup-color-project, lightbluedark);
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
          position: absolute;
          content: '';
          top: -12px;
          left: 18px;
          height: 0;
          width: 0;
        }
      }
    }


    .prj--vh-map-marker {
      display: block;

      .prj--vh-map-marker-item {
        position: absolute;
        margin-left: -9px;
        margin-top: -9px;
        height: 18px;
        width: 18px;
        left: 0;
        top: 0;

        @media(min-width: $breakpoint-xlarge) {
            margin-left: -12px;
            margin-top: -12px;
            height: 24px;
            width: 24px;
        }

        .prj--vh-map-marker-item-trigger {
          background: map-get($wup-color-project, red);
          transition: background .2s ease-in;
          border-radius: 999px;
          position: relative;
          text-align: center;
          color: $wup-white;
          padding-top: 4px;
          cursor: pointer;
          font-size: 10px;
          display: block;
          height: 100%;
          width: 100%;
          z-index: 1;

          @media(min-width: $breakpoint-xlarge) {
              padding-top: 5px;
              font-size: 14px;
          }

          &.prj--is-equal,
          &.prj--is-pulse {
            &:after {
              content: '';
              position: absolute;
              display: block;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              border-radius: 100%;
              background: rgba(0,0,0,.6);
              opacity: 0;
              animation-name: spotPulse;
              animation-duration: .4s;
              animation-iteration-count: 1;
              animation-direction: linear;
              z-index: 0;
            }
          }


          &:hover,
          &.prj--is-equal {
            background: map-get($wup-color-project, blue);
          }

          &.prj--is-active {
            transform: rotate(45deg);
            animation-name: rotateThis45;
            animation-duration: .3s;
          }
        }

        &.prj--is-mini {
          background: map-get($wup-color-project, red);
          border-radius: 999px;
          margin-left: -4px;
          margin-top: -4px;
          height: 8px;
          width: 8px;

          @media(min-width: $breakpoint-xlarge) {
              margin-left: -6px;
              margin-top: -6px;
              height: 12px;
              width: 12px;
          }
        }

        .prj--vh-map-marker-item-content {
          background: map-get($wup-color-project, blue);
          padding: 0 10px;
          margin-top: -71.5px;
          position: absolute;
          color: $wup-white;
          font-size: .7em;
          display: none;
          left: -260px;
          width: 250px;
          z-index: 10;
          top: 50%;

          @media(min-width: $breakpoint-medium) {
              left: -305px;
              width: 290px;
          }

          @media(min-width: $breakpoint-xlarge) {
              left: -495px;
              width: 480px;
              padding: 12px 18px 12px 170px;
              font-size: .85em;
          }

          &:after {
            border-top: 12px solid transparent;
          	border-left: 12px solid map-get($wup-color-project, blue);
          	border-bottom: 12px solid transparent;
            position: absolute;
            margin-top: -12px;
            right: -12px;
            content: '';
            height: 0;
            top: 50%;
            width: 0;
          }

          p,
          h4 {
            margin: 2px 0;

            @media(min-width: $breakpoint-medium) {
                margin: 8px 0;
            }
          }

          .prj--vh-map-marker-item-content-img {
              display: none;

            @media(min-width: $breakpoint-xlarge) {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                display: block;
                height: 100%;
                width: 150px;
                left: 0;
                top: 0;
            }
          }


          // Alternative Positionen

          &.prj--is-top {
            top: initial !important;
            left: -122px !important;
            bottom: 30px;

            @media(min-width: $breakpoint-medium) {
                left: -142px !important;
            }

            @media(min-width: $breakpoint-xlarge) {
                left: -234px !important;
                bottom: 40px;
            }

            &:after {
              border-bottom: 0;
              border-right: 12px solid transparent;
              border-top: 12px solid #0f2455;
              border-left: 12px solid transparent;
              right: initial !important;
              top: initial !important;
              margin-left: -6px;
              margin-top: 0;
              bottom: -12px;
              left: 50%;
            }
          }


          &.prj--is-bottom {
            margin-top: 0 !important;
            left: -122px !important;
            top: 30px;

            @media(min-width: $breakpoint-medium) {
                left: -142px !important;
            }

            @media(min-width: $breakpoint-xlarge) {
                left: -234px !important;
                top: 40px;
            }

            &:after {
              border-top: 0;
              border-right: 12px solid transparent;
              border-bottom: 12px solid #0f2455;
              border-left: 12px solid transparent;
              right: initial !important;
              margin-left: -6px;
              margin-top: 0;
              top: -12px;
              left: 50%;
            }
          }
        }
      }
    }
  }
}
