//	CSS for the searchfield

@import "../inc/variables";

.mm-search,
.mm-search input
{
	@include vendor-prefix( "box-sizing", border-box );
}
.mm-search
{

	background: inherit;
	width: 100%;
	height: $btnSize + $padding;
	padding: $padding;
	position: relative;
	top: 0;
	z-index: 2;

	input
	{
		@include font;
		
		border: none;
		border-radius: ( $btnSize - $padding ) / 2;
		line-height: $btnSize - $padding;
		outline: none;
		display: block;
		width: 100%;
		height: $btnSize - $padding;
		margin: 0;
		padding: 0 $padding;
	}
}
.mm-menu
{
	li.mm-nosubresults > a.mm-subopen
	{
		display: none;
	
		+ a, 
		+ span
		{
			padding-right: 10px;
		}
	}
	li.mm-noresults
	{
		text-align: center;
		font-size: 20px;
		display: none;
		padding-top: $btnSize * 2;
		
		&:after
		{
			border: none;
		}
	}
	&.mm-noresults li.mm-noresults
	{
		display: block;
	}

	&.mm-hassearch .mm-panel
	{
		padding-top: $btnSize + ( $padding * 2 )
	}
}

@include colors_search;