.slick-prev,
.slick-next {
  border: 0;
  cursor: pointer;
  background: transparentize(map-get($wup-color-project, blue), .2);
  transition: background .2s ease-in;
  position: absolute;
  text-align: center;
  font-size: 0px;
  height: 52px;
  width: 36px;
  z-index: 1;
  top: 10%;

  @media(min-width: $breakpoint-medium) {
    height: 72px;
    width: 56px;
  }

  &:hover {
    background: map-get($wup-color-project, blue);
  }

  &:before {
    font-family: "FontAwesome", "Font Awesome 5 Free";
    color: $wup-white;
    font-size: 30px;
  }
}

.slick-prev {
  right: 34px;

  @media(min-width: $breakpoint-medium) {
    right: 50px;
  }

  &:before {
    font-family: "FontAwesome", "Font Awesome 5 Free";
    content: fa-content($fa-var-angle-left);
    font-weight: 900;
  }
}

.slick-next {
  right: -4px;

  @media(min-width: $breakpoint-medium) {
    right: -8px;
  }

  &:before {
    font-family: "FontAwesome", "Font Awesome 5 Free";
    content: fa-content($fa-var-angle-right);
    font-weight: 900;
  }
}



.slick-slide {
  position: relative;
}



.slick-slider {

  &:not(.slick-initialized) {

    & > div {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }
}



.slick-dots {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    margin-right: 8px;

    button {
      background: transparentize(map-get($wup-color-project, red), .8);
      border: 0;
      width: 16px;
      height: 16px;
      font-size: 0px;
      transition: background .2s ease-in;
      cursor: pointer;

      &:hover {
        background: transparentize(map-get($wup-color-project, red), .6);
      }
    }

    &.slick-active {

      button {
        background: map-get($wup-color-project, red);
      }
    }
  }
}
