input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
textarea,
select {
	line-height: 20px;
	box-sizing: border-box;
}
select {
	line-height: 18px;
}

.wup-btn {
	box-sizing: border-box;
	line-height: 20px;
}

.prj--label {
	display: flex;
	flex-direction: column;
	margin: 0;

	&-row {
		flex-direction: row;
	}

	&-name {
		@include disable-selection;

		display: block;
	}

	input[type="text"] {
		display: block;
		width: 100%;
	}
}

.prj--checkbox-wrapper {
	display: flex;
	align-items: center;
	height: $wup-line-height-default;
}
