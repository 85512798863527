input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  background-color: $wup-white;
  border: 1px solid map-get($wup-color-neutral, gray15);
  border-radius: $wup-button-border-radius;
  padding: 10px 15px;

  &:focus {
    border-color: map-get($wup-color-neutral, gray15);
  }
}

input[type='checkbox'],
input[type='radio'] {

  &:focus {
    border-color: map-get($wup-color-neutral, gray15);
  }
}

label {
  display: inline-block;
  font-size: $wup-font-size-small;
  font-weight: normal;
  margin: 5px 0;
}

select {
  background-image: url('../img/select-sort.png');
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 20px;
  padding-right: 30px;
}

select::-ms-expand {
  display: none;
}

// Nice RADIO / CHECKBOX

.wup-nice-radio-check-group {

  label {
    cursor: pointer;
    display: inline-block;
    margin: 10px 30px 10px 0;
  }
}


.wup-nice-radio {
  cursor: pointer;

  .wup-status {
    background: $wup-white;
    border-radius: 999px;
    display: inline-block;
    height: 16px;
    margin-right: 8px;
    margin-top: 1px;
    position: relative;
    vertical-align: top;
    width: 16px;

    @media(min-width: $breakpoint-medium) {
      width: 20px;
      height: 20px;
    }
  }

  input {
    display: none !important;
  }

  &.wup-nice-radio-checked {

    .wup-status {

      &:before {
        background: map-get($wup-color-project, primary);
        border-radius: 999px;
        content: '';
        height: 8px;
        left: 4px;
        position: absolute;
        top: 4px;
        width: 8px;

        @media(min-width: $breakpoint-medium) {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}



.wup-nice-checkbox {
  cursor: pointer;

  .wup-status {
    background: $wup-white;
    border-radius: 2px;
    display: inline-block;
    height: 16px;
    margin-right: 5px;
    margin-top: 1px;
    position: relative;
    vertical-align: top;
    width: 16px;
    vertical-align: bottom;

    @media(min-width: $breakpoint-medium) {
      width: 20px;
      height: 20px;
    }

    input {
      display: none !important;
    }
  }

  &.wup-nice-checkbox-checked {

    .wup-status {

      &:before {
        border-radius: 999px;
        color: map-get($wup-color-project, primary);
        content: "\f00c";
        font-family: "FontAwesome";
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        text-align: center;
        width: 16px;
      }
    }
  }
}
