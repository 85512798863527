.prj--hr-with-text {
  position: relative;
  text-align: center;
  margin: map-get($wup-spaces, large) 0;

  &:before {
    background: map-get($wup-color-project, lightbluelight);
    position: absolute;
    margin-top: -1px;
    height: 2px;
    content: '';
    right: 0;
    top: 50%;
    left: 0;
  }

  span {
    color: map-get($wup-color-project, lightbluedark);
    text-transform: uppercase;
    background: $wup-white;
    letter-spacing: 2px;
    position: relative;
    font-weight: bold;
    padding: 0 1em;
  }
}
