
// Normal Lists
.wup-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}


// Nested Lists
.wup-list ul {
	padding-left: $wup-list-nested-padding;
}



// Horizontal Inline List
.wup-list-inline {
	@extend .wup-list;

	> li {
		display: inline-block;

		&:not(:last-child) {
			margin-right: $wup-list-inline-margin;
		}
	}
}



// Liste kommasepariert

.wup-list-comma {
	@extend .wup-list-inline;

	li {
		margin-right: 0 !important;

		&:after {
			content: ', ';
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

.wup-list-comma-parent {

	ul {
		@extend .wup-list-comma;
	}
}
