.prj--teaser {
  position: relative;
  margin: 1em 0;

  img {
    display: block;
  }

  .prj--overlay {
    background: transparentize(map-get($wup-color-project, blue), .2);
    font-size: $wup-font-size-small;
    padding: 1em 1.1em .7em;
    position: absolute;
    color: $wup-white;
    max-width: 80%;
    bottom: -1em;
    left: 1em;

    &.prj--overlay-red {
      background: transparentize(map-get($wup-color-project, red), .2);
    }

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }

    p {
      margin: .5em 0;
    }

    a {
      color: $wup-white;
    }
  }
}
