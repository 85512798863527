.wup-alert {
	background-color: map-get($wup-color-neutral, gray05);
	color: map-get($wup-color-neutral, gray55);
	font-weight: 700;
	font-size: $wup-font-size-small;
	padding: 1em;
	margin: 1em 0;

	&.wup-alert--success {
		background-color: lighten(map-get($wup-color-status, success), 38);
		color: map-get($wup-color-status, success);
	}

	&.wup-alert--info {
		background-color: lighten(map-get($wup-color-status, info), 44);
		color: map-get($wup-color-status, info);
	}

	&.wup-alert--warning {
		background-color: lighten(map-get($wup-color-status, warning), 44);
		color: map-get($wup-color-status, warning);
	}

	&.wup-alert--error {
		background-color: lighten(map-get($wup-color-status, danger), 35);
		color: map-get($wup-color-status, danger);
	}
}
