.alignright {
	float:right;
	margin-left:20px;
}

.alignleft {
	float:left;
	margin-right:20px;
}

.aligncenter {
	margin: 0 auto;
	text-align: center;
}
