//	Arrows
@mixin arrow
{
	content: '';
	border: 2px solid transparent;
	display: block;
	width: 7px;
	height: 7px;
	margin-bottom: -5px;
	position: absolute;
	bottom: 50%;
	
	@include vendor-prefix( "transform", rotate( -45deg ) );
}
@mixin arrow-prev
{
	border-right: none;
	border-bottom: none;
}
@mixin arrow-next
{
	border-top: none;
	border-left: none;
}