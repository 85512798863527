@mixin wup-new-button-classes($wup-background, $wup-color) {

  $wup-background-hover: lighten($wup-background, 10%);
  $wup-background-active: darken($wup-background, 10%);


  // States des Buttons

  &:focus,
  &:visited {
    background-color: $wup-background;
    color: $wup-color;
    outline: none;
  }

  &:hover {
    background-color: $wup-background-hover;
    border-color: $wup-background-hover;
    color: $wup-color;
    text-decoration: none;
  }

  &:active {
    background-color: $wup-background-active;
    color: $wup-color;
  }


  // Farbspezifische Variationen

  &.wup-btn-inverse {
    background-color: $wup-color !important;
    border-color: $wup-color !important;
    color: $wup-background;

    &:focus,
    &:visited {
      color: $wup-background;
    }

    &:hover {
      color: $wup-background-hover;
    }

    &:active {
      color: $wup-background-active;
    }

  }

  &.wup-btn-border {
    background-color: transparent;
    border: $wup-button-border-width solid $wup-background;
    color: $wup-background;

    &:focus,
    &:visited {
      background-color: transparent;
      color: $wup-background;
    }

    &:hover {
      background-color: $wup-background;
      color: $wup-color;
    }

    &:active {
      background-color: $wup-background-active;
      color: $wup-color;
    }

  }
}



/*****************************************************************/
/*****************************************************************/
/*****************************************************************/



// Default Button wird angelegt

.wup-btn {
  border-radius: $wup-button-border-radius;
  transition: $wup-button-easing;
  text-transform: $wup-button-text-transform;
  font-family: $wup-button-font-family;
  font-weight: $wup-button-font-weight;
  font-size: $wup-button-default-font-size;
  line-height: $wup-line-height-default;
  padding: $wup-button-default-padding;
  vertical-align: middle;
  text-decoration: none;
  display: inline-block;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  border: none;

  background-color:$wup-button-default-background;
  border: $wup-button-border-width solid $wup-button-default-background;
  color:$wup-button-default-color;

  @include wup-new-button-classes($wup-button-default-background, $wup-button-default-color);



  // Button Größen

  @each $wup-name, $wup-data in $wup-buttonTypes {

    &.wup-btn-#{$wup-name} {
      font-size: map-get($wup-data, fontsize);
      padding: map-get($wup-data, padding);
    }

  }


  // Weitere Variationen

  &.wup-btn-block {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }

  &.wup-btn-rounded {
    border-radius: 999px !important;
  }

  &.wup-btn-squared {
    border-radius: 0 !important;
  }
}


/*****************************************************************/
/*****************************************************************/
/*****************************************************************/

// Colors Map wird geloopt => siehe Settings

@each $wup-groupName, $wup-groupData in $wup-colors {
  @each $wup-colorName, $wup-colorValue in $wup-groupData {

    .wup-btn-#{$wup-colorName} {
      @include wup-new-button-classes($wup-colorValue, #fff);

      background-color: $wup-colorValue;
      border: $wup-button-border-width solid $wup-colorValue;
      color: $wup-white;
    }

  }
}
